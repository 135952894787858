import React, { useState } from 'react';
import { Container, Form } from 'semantic-ui-react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import Report from './Report';


function App() {
  const [report, setReport] = useState(null);

  const submitFiles = event => {
    event.preventDefault();
    fetch(event.target.action, {
      method: 'POST',
      body: new FormData(event.target)
    }).then(resp => resp.json())
    .then(data => {
      console.log(data)
      setReport(data)
    })
  }

  return (
    <div className="App">
      <Container>
        <h1>Citation Report</h1>
        {
            report ?
              (
                <Report report={report} />
              )
              : (
                <Form method='POST' encType="multipart/form-data" action={`${process.env.REACT_APP_API_URI}/match`} onSubmit={submitFiles.bind(this)} >
                  <Form.TextArea 
                    label='CV Citations'
                    placeholder='Paste your citations here, 1 per line'
                    name='cv' 
                    rows='30' 
                    cols='50' 
                    type='textarea'>  
                  </Form.TextArea>
                  <Form.Input
                    label='SCOPUS File'
                    name='scopus'
                    type='file'
                    />
                  <br/>
                  <Form.Button
                    type='submit'>
                      Submit
                  </Form.Button>
                </Form>
              )
            
        }
      </Container>
    </div>
  );
}

export default App;
