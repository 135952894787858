import React, { useState } from "react";
import { Container, Divider, Form, Icon, Modal, Popup, Select, Table } from "semantic-ui-react";

const Report = props => {
    const [report, setReport] = useState(props.report);
    const [render, setRender] = useState(0);

    var unmatched = report ? (report.unmatchedReferenceCitations
            .map(i => [i, report.referenceCitations[i]])
            .map(c => [c[0], c[1].citationData])
            .map(c => ({key: c[0], value: c[0], index: c[0], citation: c[1]}))
        ) : null;

    return (<div>
        <Table celled>
            <Table.Header>
                <Table.HeaderCell>Number</Table.HeaderCell>
                <Table.HeaderCell>Match</Table.HeaderCell>
                <Table.HeaderCell>Citation</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                {
                    report?.sourceDocumentCitations?.map((citation, i) => 
                        <Row report={report}
                            unmatched={unmatched}
                            citation={citation}
                            i={i}
                            addMatch={(documentCitation, referenceCitation) => {
                                report.matchedCitations.push({documentCitation, referenceCitation})
                                report.unmatchedDocumentCitations = report.unmatchedDocumentCitations.filter(c => c !== documentCitation)
                                report.unmatchedReferenceCitations = report.unmatchedReferenceCitations.filter(c => c !== referenceCitation)
                                setReport(report);
                                setRender(render + 1)
                            }}
                            removeMatch={(documentCitation, referenceCitation) => {
                                report.matchedCitations = report.matchedCitations.filter(m => m.documentCitation !== documentCitation)
                                report.unmatchedDocumentCitations.push(documentCitation)
                                report.unmatchedReferenceCitations.push(referenceCitation)
                                setReport(report);
                                setRender(render + 1)
                            }}
                            />
                    )
                }
            </Table.Body>
        </Table>
    </div>)
}

const SelectMatch = props => {
    const [filter, setFilter] = useState(null);
    const [open, setOpen] = useState(false);

    const select = index => {
        setOpen(false)
        if (props.onSelect) {
            props.onSelect(index);
        }
    }

    return (
        <Modal
            trigger={<span style={{textDecoration:'underline',cursor:'pointer'}}>{props.matchIndex || 'Find'}</span>}
            header='Select Matching SCOPUS Citation'
            size='large'
            closeIcon
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            content={
                <Container>
                    <Divider />
                    <Container>
                        {props.targetCitation?.text}
                    </Container>
                    <Divider />
                    <Form>
                        <Form.Input type='text' placeholder='Search' value={filter} onChange={e => setFilter(e.target.value)} />
                    </Form>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell>Number</Table.HeaderCell>
                            <Table.HeaderCell>Authors</Table.HeaderCell>
                            <Table.HeaderCell>Title</Table.HeaderCell>
                            <Table.HeaderCell>Publisher</Table.HeaderCell>
                            <Table.HeaderCell>Year</Table.HeaderCell>
                            <Table.HeaderCell>Vol</Table.HeaderCell>
                            <Table.HeaderCell>Issue</Table.HeaderCell>
                            <Table.HeaderCell>Page</Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {props.options.filter(c => filterCitation(filter, c.citation)).map(c => 
                                <Table.Row key={c.key} onClick={e => select(c.index)}>
                                    <Table.Cell>{c.index + 1}</Table.Cell>
                                    <Table.Cell>{c.citation.authors}</Table.Cell>
                                    <Table.Cell>{c.citation.title}</Table.Cell>
                                    <Table.Cell>{c.citation.publisher}</Table.Cell>
                                    <Table.Cell>{c.citation.year}</Table.Cell>
                                    <Table.Cell>{c.citation.volume}</Table.Cell>
                                    <Table.Cell>{c.citation.issue}</Table.Cell>
                                    <Table.Cell>{c.citation.firstPage}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Container>
            }
            />
    );
}

const filterCitation = (filter, citation) => {
    if (!filter) {
        return true;
    }

    return filterValue(filter, citation.title) ||
        filterValue(filter, citation.authors) ||
        filterValue(filter, citation.publisher) ||
        filterValue(filter, citation.year) ||
        filterValue(filter, citation.volume) ||
        filterValue(filter, citation.issue) ||
        filterValue(filter, citation.firstPage)
}

const filterValue = (filter, value) => {
    return filter && value && value.toLowerCase().indexOf(filter.toLowerCase()) > -1;
}

const Row = props => {
    const {report, unmatched, citation, i} = props;
    var match = report?.matchedCitations?.filter(m => m.documentCitation === i)[0];
    const matchIndex = match?.referenceCitation;
    var matchCitation = match ? report.referenceCitations[matchIndex] : null;

    return (
        <Table.Row positive={match} negative={!match}>
            <Table.Cell>{i + 1}</Table.Cell>
            <Table.Cell>
                { match ? (
                    <Popup
                        content={matchCitation?.citationData?.title}
                        trigger={
                            <span>
                                <span style={{textDecoration:'underline',cursor:'pointer'}}>{matchIndex + 1}</span>
                                <Icon
                                    name='delete'
                                    style={{cursor:'pointer'}} 
                                    onClick={() => {
                                        if (props.removeMatch) {
                                            props.removeMatch(match.documentCitation, match.referenceCitation);
                                        }
                                    }}
                                    />
                            </span>
                        }
                        />
                    ) : (
                        <SelectMatch
                            matchIndex={matchIndex}
                            options={unmatched}
                            targetCitation={citation}
                            onSelect={selection => {
                                if (props.addMatch) {
                                    props.addMatch(i, selection);
                                }
                            }}
                            />
                    )
                }
            </Table.Cell>
            <Table.Cell>{citation.text}</Table.Cell>
        </Table.Row>
    )
}

export default Report;